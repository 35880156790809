import React from "react";

const Header = () => {
  return (
    <div className="shadow flex justify-center text-center bg-[#CAE4FA] text-[14px] p-1 mb-5">
      Help us spread the word - ⭐ OpenSign on{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://github.com/opensignlabs/opensign"
        className="ml-[2px] underline"
      >
        GitHub
      </a>
    </div>
  );
};

export default Header;
