import React from "react";
import backgroundImage from "../assets/backgroundImage.jpg";
import rocket from "../assets/rocketImage.png";
import contractLogo from "../assets/svgContract.svg";
import video4 from "../assets/video4.mp4";
import video1 from "../assets/video1.mp4";
import video2 from "../assets/video2.mp4";
import video3 from "../assets/video3.mp4";
import logo from "../assets/logo.png";
import "../index.css";

function Home() {
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="h-[50px] lg:h-[70px] w-auto overflow-hidden p-1">
          <img className="object-contain h-full w-auto" alt="img" src={logo} />
        </div>
        <div className="hidden md:inline-block">
          <a
            href="https://app.opensignlabs.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#ff4040] boxShadow text-[#ffffff] mr-4 font-bold flex gap-2 py-2 px-16 md:px-2 lg:px-5 rounded-2xl hover:rounded-full cursor-pointer"
          >
            <img
              alt="rocket"
              className="w-[25px] h-[30px] lg:w-[25px] lg:h-[30px] md:w-[20px] md:h-[20px]"
              src={rocket}
            />
            <span className="md:text-sm lg:text-lg">Get started</span>
          </a>
        </div>
      </div>

      <div
        className="bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="text-white flex gap-0 p-5 md:p-10 pt-5 md:pt-14 md:pl-0 ">
          <div className="hidden md:inline-block md:w-[12%]"></div>
          <div className="w-full md:w-[88%] flex flex-col md:flex-row justify-center md:justify-start">
            <div className="flex flex-col text-white ">
              <span className="text-2xl md:text-[46px] text-[#ffffff] leading-tight">
                Seal the Deal, Openly.
                <br />
                Welcome to OpenSign™
              </span>
              <span className="text-lg md:text-[27px] md:mt-5">
                Your ultimate open source PDF E-Signature Solution.
              </span>

              <span className="mt-2 text-sm text-[#dbdbdb] leading-loose">
                Transform the Way You Sign, Store, and Secure Your Documents.
                <br />
                All in One Place - All for Free.
              </span>

              <span className="mt-3 text-[#ffffff]">
                Discover our new FREE plan with unlimited e-signatures!
              </span>

              <div className="flex flex-col md:flex-row gap-5 lg:gap-10 p-2 items-center text-[17px] ">
                <a
                  href="https://app.opensignlabs.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#ff4040] font-bold flex gap-2 py-3 px-16 md:px-2 lg:px-10 rounded-lg hover:rounded-2xl cursor-pointer"
                >
                  <img
                    alt="rocket"
                    className="w-[25px] h-[30px] lg:w-[25px] lg:h-[30px] md:w-[20px] md:h-[20px]"
                    src={rocket}
                  />
                  <span className="md:text-sm lg:text-lg">Get started</span>
                </a>
                <a
                  href="https://github.com/OpenSignLabs/OpenSign"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#ffffff] font-medium flex gap-2 text-black py-3 px-14  md:px-2 lg:px-10 items-center rounded-lg hover:rounded-2xl"
                >
                  <i class="fa-brands fa-github md:text-sm lg:text-xl"></i> {""}
                  <span className="md:text-sm lg:text-lg">Star On Github</span>
                </a>
              </div>
              <div className="flex gap-3 ml-3 mb-5 cursor-pointer justify-center md:justify-start">
                <a
                  href="https://www.twitter.com/opensignlabs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    aria-hidden="true"
                    className="fa-brands fa-square-x-twitter text-xl"
                  ></i>
                  <span className="fa-sr-only">OpenSign&apos;s Twitter</span>
                </a>
                <a
                  href="https://www.linkedin.com/company/opensign%E2%84%A2/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    aria-hidden="true"
                    className="fa-brands fa-linkedin text-xl"
                  ></i>
                  <span className="fa-sr-only">OpenSign&apos;s LinkedIn</span>
                </a>

                <a
                  href="https://www.facebook.com/OpenSignHQ/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    aria-hidden="true"
                    class="fa-brands fa-facebook text-xl"
                  ></i>
                  <span className="fa-sr-only">OpenSign&apos;s Github</span>
                </a>

                <a
                  href="https://discord.com/invite/xe9TDuyAyj"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    aria-hidden="true"
                    className="fa-brands fa-discord text-xl"
                  ></i>
                  <span className="fa-sr-only">OpenSign&apos;s Discord</span>
                </a>
              </div>
            </div>
            <div className="mt-10">
              <img
                alt="contract"
                className="w-[95%] h-[90%] md:w-[100%] md:h-[100%]"
                src={contractLogo}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex mb-20 ">
        <div className="hidden md:inline-block w-[15%]"></div>
        <div className="w-full md:w-[70%] flex flex-col p-7 pb-0 md:p-0 ">
          <div className="flex flex-col md:flex-row h-[25%] ">
            <div className="order-2 md:order-1 flex flex-col mt-3 md:mt-12 w-full md:w-[50%] ">
              <span className="text-[18px] text-[#000000] font-semibold">
                Unlimited Free Signatures
              </span>
              <span>
                Experience the freedom of limitless digital signing without ever
                reaching for your wallet.
              </span>
            </div>
            <div className="order-1 md:order-2 flex justify-center w-full md:w-[50%]">
              <video
                className="w-[100%] h-[100%] md:h-[90%] md:w-[50%]"
                muted
                autoPlay
                src={video3}
                playsInline
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row h-[25%] ">
            <div className="flex justify-center w-full md:w-[50%]">
              <video
                className="w-[100%] h-[100%] md:h-[90%] md:w-[50%]"
                muted
                autoPlay
                src={video4}
                playsInline
              />
            </div>
            <div className="flex flex-col mt-4 md:mt-12 w-full md:w-[50%]">
              <span className="text-[18px] text-[#000000] font-semibold">
                Invite and Collaborate for Free
              </span>
              <span>
                Bring multiple people into the signing process at no extra cost.
                Close deals, not your wallet.
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row h-[25%] ">
            <div className="order-2 md:order-1 flex flex-col mt-4 md:mt-12 w-full md:w-[50%]">
              <span className="text-[18px] text-[#000000] font-semibold">
                Certification, Also Free
              </span>
              <span>
                Every signed document comes with a detailed completion
                certificate, featuring access logs, all at zero cost.
              </span>
            </div>
            <div className="order-1 md:order-2 flex justify-center w-full md:w-[50%]">
              <video
                className="w-[100%] h-[100%] md:h-[90%] md:w-[50%]"
                muted
                autoPlay
                src={video2}
                playsInline
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row h-[25%] ">
            <div className="flex justify-center w-full md:w-[50%]">
              <video
                className="w-[100%] h-[100%] md:h-[90%] md:w-[50%]"
                muted
                autoPlay
                src={video1}
                playsInline
              />
            </div>
            <div className="flex flex-col mt-10 md:mt-12 w-full md:w-[50%]">
              <span className="text-[18px] text-[#000000] font-semibold">
                OpenSign™, Secure and Free
              </span>
              <span>
                Your documents deserve a safe home. Store and organize them for
                free in our secure OpenSign™ Drive vault.
              </span>
            </div>
          </div>
        </div>
        <div className="hidden md:inline-block w-[15%]"></div>
      </div>
    </div>
  );
}

export default Home;
