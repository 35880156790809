import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../style/loader.css";
import Loader from "../components/Loader";
import Title from "../components/Title";
import dp from "../../src/assets/dp.png";

function TemplateList() {
  const navigate = useNavigate();
  const { username } = useParams();
  const [publicTemplateList, setPublicTemplateList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [ownerUserDetails, setOwnerUserDetails] = useState("");
  const [extend_User, setExtend_User] = useState();

  useEffect(() => {
    getTemplateDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getTemplateDetails = async () => {
    try {
      const params = {
        username: username,
      };
      const axiosRes = await axios.post(
        `${localStorage.getItem("baseUrl")}/functions/getpublictemplate`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
          },
        }
      );
      if (axiosRes?.data?.result) {
        setPublicTemplateList(axiosRes.data.result?.template);
        setOwnerUserDetails(axiosRes.data.result?.user);
        setExtend_User(axiosRes.data.result?.extend_User);
      }
      setIsLoader(false);
    } catch (e) {
      // console.log("e", e);
      setIsLoader(false);
    }
  };
  const handleonclick = (data) => {
    navigate(`/publicsign?templateid=${data.objectId}`);
  };

  return (
    <div className=" w-full h-full relative">
      <Title searchIndex={extend_User?.SearchIndex} />
      {isLoader ? (
        <Loader />
      ) : (
        <div className=" p-10 ">
          <div className="flex flex-col items-center justify-center ">
            <div className="w-[75px] h-[75px] rounded-full ring-[2px] ring-offset-2 ring-gray-300 overflow-hidden">
              <img
                className="w-full h-full object-contain"
                src={ownerUserDetails?.ProfilePic || dp}
                alt="Profile"
              />
            </div>
            <div className="flex flex-col items-center">
              <p className="font-bold uppercase">{ownerUserDetails?.name}</p>
              <span className="text-[11px]">{extend_User?.Tagline}</span>
            </div>
          </div>
          {publicTemplateList && publicTemplateList.length > 0 ? (
            <div className="shadow-sm border-x-[0.1px] border-t-[0.1px] border-gray-250 mt-5 md:mx-40 ">
              {publicTemplateList.map((data, ind) => {
                return (
                  <div
                    className="container bg-white hover:bg-gray-100 p-2 flex justify-between md:items-center border-b-[0.1px]  cursor-pointer border-[#e9e9ea]"
                    key={ind}
                  >
                    <div className="flex flex-col px-5 ">
                      <span className="font-medium text-[14px]">
                        {data.Name}
                      </span>
                      <span className="hidden md:inline-block text-[13px]">
                        {data.Description}
                      </span>
                    </div>

                    <button
                      htmlFor="my_modal_6"
                      onClick={() => handleonclick(data)}
                      className="op-btn op-btn-sm op-btn-outline op-btn-primary"
                    >
                      Sign Now
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex py-7 justify-center">
              <p className="text-gray-600">
                No documents have been shared publicly yet. When available, they
                will appear here for viewing and signing.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TemplateList;
