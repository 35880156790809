import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import Header from "../components/Header";

function Layout() {
  return (
    <div className="flex flex-col z-50 min-h-[100vh]">
      <Header/>
      <main className="flex-1">
        <Outlet />
      </main>
      <footer className="op-footer op-footer-center p-4 bg-base-200 text-base-content rounded gap-y-[10px]">
        <nav>
          <div className="grid grid-flow-col gap-4 text-[25px] cursor-pointer">
            <NavLink
              to="https://github.com/opensignlabs/opensign"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i aria-hidden="true" className="fa-brands fa-github text-10"></i>
              <span className="fa-sr-only">OpenSign&apos;s Github</span>
            </NavLink>
            <NavLink
              to="https://www.linkedin.com/company/opensign%E2%84%A2/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i aria-hidden="true" className="fa-brands fa-linkedin"></i>
              <span className="fa-sr-only">OpenSign&apos;s LinkedIn</span>
            </NavLink>
            <NavLink
              to="https://www.twitter.com/opensignlabs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                aria-hidden="true"
                className="fa-brands fa-square-x-twitter"
              ></i>
              <span className="fa-sr-only">OpenSign&apos;s Twitter</span>
            </NavLink>
            <NavLink
              to="https://discord.com/invite/xe9TDuyAyj"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i aria-hidden="true" className="fa-brands fa-discord"></i>
              <span className="fa-sr-only">OpenSign&apos;s Discord</span>
            </NavLink>
          </div>
        </nav>
        <aside>
          <span>
            {" "}
            All Rights Reserved &copy;{" "}
            <a href="https://www.opensignlabs.com" className="cursor-pointer">OpenSign™</a>
          </span>
        </aside>
      </footer>
    </div>
  );
}

export default Layout;
