import React from "react";
import { Helmet } from "react-helmet";

function Title({ title, searchIndex }) {
  let searchIndexValue;
  if (searchIndex === undefined) {
    searchIndexValue = true; // Default value if undefined
  } else {
    searchIndexValue = searchIndex; // Set to true or false based on actual value
  }
  return (
    <Helmet>
      <title>{title ? `${title} - OpenSign™` : "OpenSign™"}</title>
      <meta name="description" content={`${title} - OpenSign™`} />
      {searchIndexValue && <meta name="robots" content="noindex" />}
      <link
        rel="icon"
        type="image/png"
        href={localStorage.getItem("fev_Icon")}
        sizes="40x40"
      />
    </Helmet>
  );
}

export default Title;
