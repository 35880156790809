import React from "react";

function Loader() {
  return (
    <div className="flex flex-col justify-center items-center h-[100vh]">
      <div className="op-loading op-loading-infinity w-[4rem] text-neutral"></div>
      <span className="text-[13px] text-base-cotent">This might take some time</span>
    </div>
  );
}

export default Loader;
